import React from 'react';

function Home() {
    return (
      <div>
        <h1>로그인후홈</h1>
      </div>
    );
  }
  
  export default Home;