import React from "react";
import NavComponent from "../../components/base/nav";
import '../../components/style/nav.css';

const Header = () => {
    return (
        <NavComponent />
    )
}

export default Header;